<template>
  <div class="seized-properties" v-if="!['partner', 'agent'].includes($store.state.me.role)">
    <div class="main__header">
      <div class="main__header__item left"></div>
      <div class="main__header__item left">
        <h1>Изъятое имущество</h1>
      </div>
      <div class="main__header__item right"></div>
    </div>
    <div v-if="error" class="messages__status error" style="width: auto; margin: 0 20px">{{ error }}</div>
    <div v-else class="main__content lk">
      <div class="tbl__header" style="width: 100%">
        <div class="tbl__filter tbl__filter--gap-20">
          <label class="tbl__filter__item">
            <input type="text" placeholder="Поиск" v-model="filter.q" @input="onInputQ">
          </label>
          <label class="tbl__filter__item" style="min-width: auto">
            <a href="https://disk.yandex.ru/i/9PINaI0dQy9iuw" target="_blank" class="link">Яндекс-диск</a>
          </label>
          <label class="tbl__filter__item" style="min-width: auto">
            <a :href="void(0)" @click="copyYandexDiskLink" class="link">Поделиться</a>
          </label>
        </div>
      </div>
      <div v-if="isLoading" class="seized-properties__container">
        <div class="seized-properties__list">
          <div
            class="seized-properties__elem"
            v-for="(_, index) in Array(20)"
            :key="index"
          >
            <div class="seized-properties__images">
              <div class="seized-properties__image sceleton"></div>
            </div>
          </div>
        </div>
      </div>
      <!-- <div v-if="isLoading" class="messages__status loading" style="width: 100%; margin: 0 20px">Загрузка...</div> -->
      <div v-else class="seized-properties__container">
        <div v-if="data.length" class="seized-properties__list">
          <div
            class="seized-properties__elem"
            v-for="(elem, index) in data"
            :key="index"
          >
            <div class="seized-properties__images" :ref="`seized-properties__images--${index}`">
              <img
                class="seized-properties__image sceleton"
                :src="`${baseURL}/api/seized-property/image?dir=${elem.title}&filename=${image}&minimal=true`"
                :alt="image"
                v-for="image in elem.images"
                :key="image"
                @click="selectElem(elem)"
                @contextmenu.prevent="event => openInNewWindow(event, elem)"
                loading="lazy"
                @load="handleImageLoad"
              />
            </div>
            <!-- <button class="seized-properties__left" @click="onScrollRoute(`seized-properties__images--${index}`, 'left')">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M0 12c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm7.58 0l5.988-5.995 1.414 1.416-4.574 4.579 4.574 4.59-1.414 1.416-5.988-6.006z" />
              </svg>
            </button>
            <button class="seized-properties__right" @click="onScrollRoute(`seized-properties__images--${index}`, 'right')">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm-1.568 18.005l-1.414-1.415 4.574-4.59-4.574-4.579 1.414-1.416 5.988 5.995-5.988 6.005z" />
              </svg>
            </button> -->
            <div
              class="seized-properties__content"
              @click="selectElem(elem)"
              @contextmenu.prevent="event => openInNewWindow(event, elem)"
            >
              <span>{{ getName(elem) }}</span>
              <span v-if="getStatus(elem)">{{ getStatus(elem) }}</span>
              <span v-if="getMileage(elem)">{{ getMileage(elem) }}</span>
              <span v-if="getYear(elem)">{{ getYear(elem) }}</span>
              <span v-if="getPrice(elem)">{{ getPrice(elem) }}</span>
              <button v-if="getAvitoLink(elem)" class="btn" @click.stop="copyAvitoLink(getAvitoLink(elem))">Поделиться</button>
            </div>
          </div>
        </div>
        <div v-else-if="!data.length && !isLoading">Нет данных</div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import _ from 'lodash'

import { copyText } from '@/helpers/utils'

export default {
  name: 'SeizedProperties',
  data () {
    return {
      baseURL: process.env.VUE_APP_HTTP,
      data: [],
      selectedElem: null,
      error: null,
      link: null,
      alt: null,
      isOpenImage: false,
      filter: {
        q: ''
      },
      isLoading: false
    }
  },
  async created () {
    await this.getData()
  },
  methods: {
    copyYandexDiskLink () {
      return copyText(this, 'https://disk.yandex.ru/i/9PINaI0dQy9iuw', 'Ссылка на Яндекс-диск скопирована в буфер обмена')
    },
    copyAvitoLink (link) {
      return copyText(this, link, 'Ссылка на Авито скопирована в буфер обмена')
    },
    handleImageLoad (event) {
      const image = event.target
      image.classList.remove('sceleton')
    },
    getAvitoLink (elem) {
      if (elem) {
        const info = {}
        Object.keys(elem.info).forEach(key => {
          info[key.trim()] = elem.info[key]
        })
        if (info && info['Авито'] && /https/.test(info['Авито'])) return info['Авито']
      }
      return null
    },
    getName (elem) {
      if (elem) {
        const info = {}
        Object.keys(elem.info).forEach(key => {
          info[key.trim()] = elem.info[key]
        })
        if (info && info['Наименование']) return info['Наименование']
        else if (elem.contract && elem.contract.property_name) return elem.contract.property_name
      } else return 'Без наименования'
    },
    getPrice (elem) {
      if (elem) {
        const info = {}
        Object.keys(elem.info).forEach(key => {
          info[key.trim()] = elem.info[key]
        })
        if (info && info['Цена']) return `${info['Цена'].toLocaleString('ru-RU')} руб.`
        else return ''
      } else return ''
    },
    getStatus (elem) {
      if (elem) {
        const info = {}
        Object.keys(elem.info).forEach(key => {
          info[key.trim()] = elem.info[key]
        })
        if (info && info['Статус']) return info['Статус']
        else return ''
      } else return ''
    },
    getMileage (elem) {
      if (elem) {
        const info = {}
        Object.keys(elem.info).forEach(key => {
          info[key.trim()] = elem.info[key]
        })
        if (info && info['Пробег'] && !isNaN(info['Пробег'])) return `${info['Пробег'].toLocaleString('ru-RU')} км`
        else return ''
      } else return ''
    },
    getYear (elem) {
      if (elem) {
        const info = {}
        Object.keys(elem.info).forEach(key => {
          info[key.trim()] = elem.info[key]
        })
        if (info && info['Год']) return `${info['Год']} г.`
        else return ''
      } else return ''
    },
    selectElem (elem) {
      this.selectedElem = elem
      this.$router.push({ params: { hash: elem.hash }, name: 'seized-properties-selected' })
    },
    openInNewWindow (event, elem) {
      event.preventDefault()
      this.selectedElem = elem
      const url = this.$router.resolve({ params: { hash: elem.hash }, name: 'seized-properties-selected' }).href
      window.open(url, '_blank')
    },
    onScrollRoute (refName, route) {
      const el = this.$refs[refName][0]
      const width = el.clientWidth

      el.scrollBy({
        left: route === 'left' ? -width : width,
        behavior: 'smooth'
      })
    },
    async getData () {
      this.isLoading = true
      const response = await axios.get(`${this.baseURL}/api/seized-property/list`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('apollo-token')}`
        },
        params: {
          q: this.filter.q
        }
      })
        .catch(() => {
          this.error = 'Не удалось получить данные'
        })
      this.data = response.data.map(elem => ({
        ...elem,
        isImageLoad: false,
        info: { ...elem.info }
      }))
      this.isLoading = false
    },
    onInputQ: _.debounce(async function () {
      await this.getData()
    }, 2_000)
  }
}
</script>

<style lang="stylus" scoped>
  .tbl__filter--gap-20
    gap 20px

  .seized-properties
    &__container
      display flex
      // min-height calc(100vh - 300px)
      width 100%

    &__list
      width 100%
      display grid
      grid-template-columns repeat(auto-fit, minmax(200px, 1fr))
      grid-gap 16px

      padding 15px

    &__elem
      height auto
      width 100%
      max-width 400px
      // border 1px solid $black
      border-radius 15px

      display grid
      grid-template-rows 200px auto

      position relative
      overflow hidden

      &:hover
        box-shadow: rgba(33,37,41,0.2) 0 20px 40px

    &__images
      display flex
      flex-direction row
      width 100%
      height 100%
      max-height 200px
      overflow hidden

    &__image
      height 100%
      min-width 100%
      max-width 100%
      object-fit cover
      object-position center
      cursor pointer

    &__content
      background-color $dark
      // opacity .6
      border-bottom-right-radius 15px
      border-bottom-left-radius 15px
      color $gray
      padding 10px
      cursor pointer

      display flex
      flex-direction column
      justify-content space-between
      align-items flex-start
      height auto

    &__left,
    &__right
      position absolute
      left 10px
      top 25%
      transform translate(-50%, -50%)
      border none
      padding 0
      border-radius 100%

    &__right
      left unset
      right 10px
      transform translate(50%, -50%)

    &__left svg,
    &__right svg
      display block
      width 12px
      height 12px
      cursor pointer

      path
        fill $dark

.seized-properties-modal
  &__images
    height 100%
    max-height 90vh
    width 100%
    max-width 800px

@keyframes loading
  0%
    background-position: 100% 50%
  100%
    background-position: 0 50%

.sceleton
  position: relative
  height: auto
  background: linear-gradient(100deg, #eceff1 30%, #f6f7f8 50%, #eceff1 70%)
  background-size: 400%
  animation: loading 1.2s ease-in-out infinite

</style>
